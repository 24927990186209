<template>
  <div class="problem-sending">
    <div class="column-1">
      <div class="problem-sending__title">{{ theme.title }}</div>

      <label for="problem-description">Опишите проблему</label>
      <form @submit.prevent action="#" method="#" id="problem-sending">
        <textarea
          name="problem-description"
          id="problem-description"
          v-model="text"
          placeholder="Текст"
        />
        <!-- v-if="!isShowGallery" -->
        <label for="upload-screenshot">
          Прикрепить скриншот экрана
          <div class="subtext">*только .jpg, .png</div>
          <!-- /.subtext -->
          <input
            @change="change"
            type="file"
            id="upload-screenshot"
            accept=".jpg, .png, .jpeg"
          />
        </label>

        <img v-if="isShowGallery" :src="preview()" :key="upd" alt="" decoding="async"/>

        <button
          class="btn problem-sending__submit"
          :disabled="inProcess"
          @click="submit"
          type="button"
        >
          Отправить
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { objToArr } from '@/utils/normalizeData'

const reader = new FileReader()

export default {
  name: 'FeedbackCreate',
  inject: ["metricsService"],
  data() {
    return {
      text: '',
      image: null,
      upd: 0,

      inProcess: false,
    }
  },
  computed: {
    themes() {
      return this.$store.state.tickets.themes
    },
    theme() {
      return objToArr(this.themes, 'key').find(
        (itm) => itm.id === +this.$route.params.theme_id
      )
    },
    isShowGallery() {
      return this.image
    }
  },
  methods: {
    async submit() {
      if (!this.text) {
        return
      }

      this.inProcess = true

      const response = await this.$api.tickets.create({
        id: this.$route.params.theme_id,
        message: this.text,
        image: this.image
      })

      if (response.status) {
        this.$store.commit('showNotification', {
          type: 'success',
          text: "Тикет добавлен успешно"
        })
        setTimeout(() => this.$router.back())
        this.inProcess = false
        this.metricsService.sendEvent("feedback")

        return
      }

      console.error("Error adding ticket'a: ", response)
      this.inProcess = false
    },
    renderPreview(event) {
      this.upd++
    },
    preview() {
      return reader.result
    },
    change(evt) {
      this.image = evt.target.files[0]
      reader.readAsDataURL(this.image)
    }
  },
  created() {
    if (!this.$route.params.theme_id || !this.themes.length) {
      this.$router.back()
    }
  },
  mounted() {
    reader.addEventListener('loadend', this.renderPreview)
  }
}
</script>

<style lang="sass">
.problem-sending
  position: relative
  display: flex
  .column-1
    width: 420px
    margin-right: 20px
  .column-2
    width: 420px
  &__title
    font-weight: bold
    font-size: 24px
    line-height: 24px
    margin-bottom: 30px
  label[for=problem-description]
    display: block
    font-size: 16px
    line-height: 18px
    margin-bottom: 15px
  textarea
    width: 100%
    height: 210px
    border: 0.5px solid #CBCBCB
    border-radius: 12px
    caret-color: #1AC386
    padding: 16px 18px
    font-size: 15px
    line-height: 19px
    margin-bottom: 10px
    &:focus
      outline: none
  #upload-screenshot
    display: none
  label[for=upload-screenshot]
    display: block
    position: relative
    padding-left: 20px
    font-size: 14px
    line-height: 14px
    cursor: pointer
    transition: 0.1s linear
    &::before
      content: url('~@/assets/images/svg/paperclip.svg')
      position: absolute
      left: 0
    &:hover
      color: #1AC386
  .subtext
    color: #CBCBCB
    font-size: 12px
    line-height: 12px
    margin-bottom: 13px
  &__submit
    width: 100%
    padding: 0 20px
    height: 55px
    transition: 0.1s linear
    background: #FFFFFF
    color: $green
    border: 1px solid #1AC386
    border-radius: 10px
  .how-to-send
    &__description
      font-size: 14px
      line-height: 18px
  .screenshots
    width: 310px
    &__title
      font-weight: 600
      font-size: 16px
      line-height: 18px
      margin-bottom: 18px
      margin-top: 55px
    &__output
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      width: 100%
      span
        width: 150px
        height: 75px
        background: #FFFFFF
        border: 1px solid #E2E2E2
        border-radius: 12px
        margin-bottom: 10px
        cursor: pointer
        img
          width: 100%
        & .selected
          border: 3px solid #1AC386
          border-radius: 12px
      .button-wrap
        width: 100%
        display: flex
        justify-content: flex-end
    button.delete-screenshot
      width: 140px
      height: 40px
      padding: 0 15px
      margin-top: 34px
      box-shadow: 0px 5px 18px rgba(95, 83, 80, 0.2), 0px 5px 18px rgba(0, 0, 0, 0.2)


// 1080
@media screen and (max-width: 1440px)
  .problem-sending
    .column-1
      width: 350px
    .column-2
      width: 350px

// 996
@media screen and (max-width: 1180px)
  .problem-sending
    .column-1
      width: 330px
    .column-2
      width: 330px

// 900
@media screen and (max-width: 1024px)
  .problem-sending
    .column-1
      width: 300px
    .column-2
      width: 300px

// 768
@media screen and (max-width: 912px)
  .problem-sending
    .column-1
      width: 260px
    .column-2
      width: 260px
    .screenshots
      width: 260px
      &__output
        .button-wrap
          justify-content: start

// 540
@media screen and (max-width: 800px)
  .problem-sending
    flex-wrap: wrap
    width: 100%
    .column-1
      width: 100%
      margin-right: 0
    .column-2
      width: 100%
    .screenshots
      width: 100%
      &__output
        justify-content: space-around
        .button-wrap
          justify-content: center
</style>
